import React from 'react'
import Navbar from './Component/Navbar'
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Component/Home'
import Fractional from './Component/Fractional'
import Membership from './Component/Membership'
import Charters from './Component/Charters'
import Lear60XR from './Component/Lear60XR'
import Challenger605 from './Component/Challenger605'
import Country from './Component/country'

const App = () => {
  return (
    <div>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/fractional' element={<Fractional/>}/>
          <Route path='/membership' element={<Membership/>}/>
          <Route path='/charters' element={<Charters/>}/>
          <Route path='/lear60xr' element={<Lear60XR/>} />
          <Route path='/challenger605' element={<Challenger605/>} />
          <Route path='/country' element={<Country/>}/>
        </Routes>
      </Router>
    </div>
  )
}

export default App
