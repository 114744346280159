import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import "../Styles/foots.css";
import axios from "axios";

const SERVICE_ID = "service_xyxopuq";
const TEMPLATE_ID = "template_0v9g1tx";
const PUBLIC_KEY = "TfV4_OMIEDYCbTtqy";

const Footerz = () =>
{
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    designation: "",
    country: "",
    email: "",
    phone: "",
    message: "",
  });
  const [stateMessage, setStateMessage] = useState("");

  const onChange = (e) =>
  {
    let key = e.target.name;
    let value = e.target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const sendMail = async (e) =>
  {
    console.log(e)
    e.preventDefault()
    if (
      formData.name == "" ||
      formData.organization == "" ||
      formData.designation == "" ||
      formData.country == "" ||
      formData.email == "" ||
      formData.phone == "" ||
      formData.message == ""
    )
    {
      alert("Please Fill All The Required Fields !");
    } else
    {
      try
      {
        const response = await emailjs.send(
          "service_30fvyfo", "template_awlh5gr",

          {
            name: `${ formData.name }`,
            organization: `${ formData.organization }`,
            designation: `${ formData.designation }`,
            country: `${ formData.country }`,
            email: `${ formData.email }`,
            phone: `${ formData.phone }`,
            message: `${ formData.message }`,
          },
          "gP9kxTsVl_9QVxrKc"
        );

        console.log("Email successfully sent!", response);
        setStateMessage("Mail sent successfully!");

        setFormData({
          message: "",
        });
      } catch (error)
      {
        console.error("Error sending email:", error);
        setStateMessage("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <>
      <div className="foot">
        <footer className="footer">
          <div id="contact" className="contact-info">
            <h3 className="fix-4" style={{ fontSize: "18px", lineHeight: "26px" }}>
              To know more about us or the programs,
              <br /> please fill in the form below or drop us a mail to{" "}
              <a href="mailto:John@asiajets.asia"> John@asiajets.asia</a>
            </h3>
          </div>

          <div className="query-form">
            <form onSubmit={sendMail}>
              <input
                onChange={(e) => onChange(e)}
                value={formData.name}
                name="name"
                type="text"
                placeholder="Name"
                required
              />
              <input
                onChange={(e) => onChange(e)}
                value={formData.organization}
                name="organization"
                type="text"
                placeholder="Organization"
                required
              />
              <input
                onChange={(e) => onChange(e)}
                value={formData.designation}
                name="designation"
                type="text"
                placeholder="Designation"
                required
              />
              <input
                onChange={(e) => onChange(e)}
                value={formData.country}
                name="country"
                type="text"
                placeholder="Country"
                required
              />
              <input
                onChange={(e) => onChange(e)}
                value={formData.email}
                name="email"
                type="email"
                placeholder="Email"
                required
              />
              <input
                onChange={(e) => onChange(e)}
                value={formData.phone}
                name="phone"
                type="tel"
                placeholder="Phone"
                required
              />
              <textarea
                placeholder="I am looking for..."
                rows="4"
                required
                onChange={(e) => onChange(e)}
                value={formData.message}
                name="message"
              ></textarea>
              {stateMessage != "" ? (
                <p
                  style={{
                    color: `${ stateMessage == "Mail sent successfully!" ? "green" : "red" }`,
                    fontSize: "25px",
                  }}
                >
                  {stateMessage}
                </p>
              ) : (
                <button type="submit">Submit</button>
              )}
            </form>
          </div>
        </footer>

      </div>
      <br />

    </>
  );
};
export default Footerz;
