import "../Styles/Challenger605.css";
// import learImg3 from '../Assets/cloud.jpg';
import React, { useEffect } from "react";
import Footerz from "./footer";
import newlogo2 from "../Assets/Bombardier_horizontal_cmyk_black.png";
const Challenger650 = () =>
{

  // document.addEventListener('DOMContentLoaded', function ()
  // {
  //   AOS.init({
  //     duration: 1000,  // Animation duration in milliseconds
  //     once: true,      // Whether animation should happen only once
  //   });
  // });
  useEffect(() =>
  {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="Challenger605" className="wrapperz">

      <div style={{ marginTop: "0px", paddingTop: "0px" }} className="section">
        <div className="bg bg1">
          {/* <h2 className="bg  desc">Challenger 605 Exterior</h2> */}
        </div>
        <p className="text">
          The Bombardier Challenger 605 delivers the  perfect balance of  comfort, space, range and speed thereby providing the utmost  convenience for its passengers. With a range of ~ 6950 kilometres  and the widest cabin in its category, the Challenger 605s' luxuriously appointed and spacious interior comfortably accommodates 10-12 passengers. <br />
          All aircraft that will be managed by us will be  Certified by and Maintained under the Smart Parts program by  Bombardier

        </p>
        <img src={newlogo2} alt="lg" className="newlg" height="150px" />

        <div className="bg bg2 slide-left" >
          {/* <h2 className="desc">Challenger 605 Exterior </h2> */}
        </div>
        {/* <p className="text">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati
          amet ex ratione est at laborum, dolor nihil necessitatibus vero,
          expedita illum voluptates tenetur eos, possimus dolorum sed ut. Enim,
          tenetur!
        </p> */}
        <div className="bg bg3 slide-right" >
          {/* <h2 className="bg  desc">Challenger 605 Exterior</h2> */}
        </div>

        {/* <p className="text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem ad
          praesentium neque pariatur repudiandae soluta. Neque officia
          necessitatibus mollitia numquam debitis reprehenderit dolorum iure
          architecto temporibus voluptatibus, odit autem fugit.
        </p> */}

        <div className="bg bg4 slide-left" >
          {/* <h2 className="bg  desc">Challenger 605 Interior Rear</h2> */}
        </div>

        {/* <p className="text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem ad
          praesentium neque pariatur repudiandae soluta. Neque officia
          necessitatibus mollitia numquam debitis reprehenderit dolorum iure
          architecto temporibus voluptatibus, odit autem fugit.
        </p> */}

        <div className="bg bg5 slide-right" >
          {/* <h2 className="bg  desc">Challenger 605 Interior Cabin</h2> */}
        </div>

        {/* <p className="text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem ad
          praesentium neque pariatur repudiandae soluta. Neque officia
          necessitatibus mollitia numquam debitis reprehenderit dolorum iure
          architecto temporibus voluptatibus, odit autem fugit.
        </p> */}

        <div className="bg bg6 slide-left" >
          {/* <h2 className="bg  desc">Challenger 605 Interior</h2> */}
        </div>
      </div>
      <Footerz />
      <p className="last">© 2024 Asia Jets</p>
    </div>

  );
};

export default Challenger650;
