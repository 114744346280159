import React from 'react';
import chartersImg from '../Assets/chr1.jpg'; 
import animationData from '../Assets/Animation - 1719565552125.json';
import Lottie from 'react-lottie';
import '../Styles/Charters.css';

const Charters = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section id="charters">
      <div className="charters-container">
        <div className="animation">
          <Lottie options={defaultOptions} height={800} width={1688} />
        </div>
        <div className="overlay">
          <h1>Experience the Luxury and Convenience of Private Jet Charters</h1>
          <p>Asia Jets is dedicated to providing unparalleled private jet charter services, ensuring your travel is both luxurious and efficient.</p>
        </div>
      </div>
      <div className="charter-container">
        <div className="charter-text">
          <h2>Charters</h2>
          <br />
          <p>
            Private jet charters offer the ultimate in flexibility and convenience, allowing you to travel on your schedule, to your destination of choice.
          </p>
          <br/>
          <p>
            With our extensive network of aircraft and experienced crew, we ensure a seamless and personalized travel experience.
          </p>
          <br/>
          <p>
            Whether for business or leisure, our charters provide the privacy and comfort you need, coupled with the highest standards of safety and service.
          </p>
        </div>
        <img src={chartersImg} alt="Charters" className="about-us-image" />
      </div>

      <footer className="footer">
        <div id='contact' className="contact-info">
          <h3>To know more, drop us a mail at <a href="mailto:John@asiajets.asia">John@asiajets.asia</a> to contact</h3>
        </div>
        <div className="query-form">
          <h3>Submit Your Query</h3>
          <br/>
          <form>
            <input type="text" placeholder="Name" required />
            <input type="text" placeholder="Organization" required />
            <input type="text" placeholder="Designation" required />
            <input type="text" placeholder="Country" required />
            <input type="email" placeholder="Email" required />
            <input type="tel" placeholder="Phone" required />
            <textarea placeholder="I am looking for..." rows="4" required></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </footer>

      <div className="footer-additional">
        <p>In Phase 1, we will be launching our services in Cambodia. Thereafter to other countries in the region.</p>
        <p>© 2024 Asia Jets</p>
      </div>
    </section>
  );
};

export default Charters;
