import "../Styles/Lear60XR.css";
import coverImg from "../Assets/main.jpg";
// import learImg from '../Assets/Picture2.png';
// import learImg2 from '../Assets/Picture3.png';
import learImg3 from "../Assets/cloud.jpg";

import newlogo2 from "../Assets/Bombardier_horizontal_cmyk_black.png";
import Footerz from "./footer";
import { useEffect } from "react";

const Lear60XR = () =>
{

  useEffect(() =>
  {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="wrapperr">
        <div style={{ marginTop: "0px", paddingTop: "0px" }} className="section">
          <div className="bga bgn ">
            {/* <h2 className="bg  desc">Learjet60XR_C_2007</h2> */}
          </div>

          <p className="texts">
            <div className="container-fractional">
              The Learjet 60 XR is a mid-size cabin, medium-range business jet
              aircraft manufactured by Bombardier Aerospace in Wichita, Kansas. With
              a range of ~ 4400 kilometres, the cabin configuration can comfortably
              seat 7-8 passengers. <br /> <br />
              All aircraft that will be managed by us will be  Certified by and Maintained under the Smart Parts program by  Bombardier.
            </div>
            <img src={newlogo2} alt="lg" className="newlglEAR" height="150px" />
          </p>


          <div className="bga  bgs">
            {/* <h2 className="desc">Learjet60XR_D_2007</h2> */}
          </div>
        </div>
        <Footerz />
        <p className="last">© 2024 Asia Jets</p>
      </div>
    </>

  );
};

export default Lear60XR;
