import React from 'react';
import membershipImg from '../Assets/msp1.png'; // Replace with your image path
import animationData from '../Assets/Animation - 1719565552125.json'; // Replace with your animation file path
import Lottie from 'react-lottie';
import '../Styles/Membership.css';

const Membership = () =>
{
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section id="membership">
      <div className="membership-container">
        <div className="animation">
          <Lottie options={defaultOptions} height={800} width={1688} />
        </div>
        <div className="overlay">
          <h1>The Asia Jets Aircraft Membership Program</h1>
          <p>
            Tailor-made for business owners & busy professionals, our program allows you to enjoy all the benefits of having an aircraft at your disposal without the headaches and hassles of ownership.
          </p>
        </div>
      </div>
      <div className="members-container">
        <div className="members-text">
          <h2>Membership Benefits</h2>
          <br />
          <p>
            Like the timeshare industry, this program allows you to 'own' the number of hours you have bought, with aircraft expenses shared among five owners. You benefit from using your time and money more effectively with utmost convenience and transparency, minus the headaches of ownership.
          </p>
          <br />
          <p>In 'Your Aircraft' you can:</p>
          <ul>
            <li>Customize your itinerary. Travel when you want and with whom you want.</li>
            <li>Take advantage of thousands of private airports worldwide and hundreds in the ASEAN region.</li>
            <li>Set your own schedule. No more flight delays or cancellations.</li>
            <li>Travel in comfort in a highly sanitized space, avoiding crowded airports and aircraft.</li>
            <li>Avoid typical airport problems like long check-ins, delays, cancellations, and lost luggage.</li>
            <li>Enjoy personalized service, where your aircraft waits for you if you are late.</li>
            <li>Fly with people you choose, be it your team, friends, or family.</li>
            <li>Fly private at a fraction of the cost of owning and operating an aircraft.</li>
          </ul>
        </div>
        <img src={membershipImg} alt="Membership" className="about-us-image" />
      </div>

      <footer className="footer">
        <div id='contact' className="contact-info">
          <h3>To know more, drop us a mail at <a href="mailto:John@asiajets.asia">John@asiajets.asia</a> to contact</h3>
        </div>
        <div className="query-form">
          <h3>Submit Your Query</h3>
          <br />
          <form>
            <input type="text" placeholder="Name" required />
            <input type="text" placeholder="Organization" required />
            <input type="text" placeholder="Designation" required />
            <input type="text" placeholder="Country" required />
            <input type="email" placeholder="Email" required />
            <input type="tel" placeholder="Phone" required />
            <textarea placeholder="I am looking for..." rows="4" required></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </footer>

      <div className="footer-additional">
        <p>In Phase 1, we will be launching our services in Cambodia. Thereafter to other countries in the region.</p>

      </div>
      <p className="last">© 2024 Asia Jets</p>
    </section>
  );
};

export default Membership;
