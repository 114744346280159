import React, { useEffect, useRef, useState } from "react";
import "../Styles/Home.css";
import areoplane1 from "../Assets/w1.png";
import areoplane2 from "../Assets/w2.jpeg";
import areoplane3 from "../Assets/w3.jpeg";
import newlogoz from "../Assets/Bombardier_vertical_cmyk_white.png";
import "@fontsource/roboto/300.css";
import Footerz from "./footer";

const Home = () =>
{
  const [visible, setVisible] = useState(false);
  const strapRef = useRef(null);

  useEffect(() =>
  {
    const observer = new IntersectionObserver(
      ([entry]) =>
      {
        if (entry.isIntersecting)
        {
          setTimeout(() =>
          {
            setVisible(true);
          }, 700); // 2-second delay
        } else
        {
          setVisible(false);
        }
      },
      {
        root: null,
        threshold: 0.1, // Adjust this value to set when the text should start fading in/out
      }
    );

    if (strapRef.current)
    {
      observer.observe(strapRef.current);
    }

    return () =>
    {
      if (strapRef.current)
      {
        observer.unobserve(strapRef.current);
      }
    };
  }, []);

  const opacity = visible ? 1 : 0;
  const transform = visible ? "translateY(0)" : "translateY(20px)";
  const backgroundPositionX = (window.pageYOffset / 10) % 100; // Adjust the divisor for speed

  return (
    <>
      <div className="wrapper">
        <div id="home" className="home-container ">
          <div className="topv">
            <video autoPlay loop muted playsInline className="video-fix">
              <source src="/homev.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="overlay">
            <h1>
              Asia Jets,
              <br /> a company headquartered in Singapore
            </h1>
            <p >
              is working towards democratising the use of Business Jets in Asia,
              starting with the ASEAN region.<br />
              Through a unique business model.{" "}
              <br />
              With Aircraft Certified by and Maintained under the Smart Parts
              program by Bombardier.
            </p>
            <img src={newlogoz} alt="lg" className="newlgz" height="200px" />
          </div>
        </div>
        <div style={{ background: "white" }} className="image-container">
          <img src={areoplane2} alt="pic2" className="image" />
          <img
            src={areoplane3}
            style={{ objectFit: "cover" }}
            alt="pic3"
            className="image"
          />
          <img
            style={{ objectFit: "cover" }}
            src={areoplane1}
            alt="pic1"
            className="image"
          />
        </div>
        {/* fix from here */}
        {/* <h2 className="hide-web">About Us</h2> */}
        <div id="about" className="about-us-container">
          <div className="about-us-image">
            <img src="/Ab1.jpg" alt="About Us" />
          </div>
          <div className="about-us-text scrollbar">
            <div className="media-fix">
              <h2 >About Us</h2>
              <br />
              <p className="respo-p" >
                Asia Jets has been established by veterans from the aviation
                sector, who have a wealth of experience in managing both Large &
                Small aviation businesses in the Commercial as well as the
                Business aviation space. The management team and its core
                members come with decades of experience spanning Management,
                Engineering and Operations, across countries. <br /> <br />
                Our objective is to provide successful business leaders with
                Best-in-class service, both inflight as well as on ground,
                backed by world class safety standards.
                <br />
                <br />
                Service that is on par with international standards, following
                rigorous safety procedures and a highly personalised inflight
                experience.
                <br /> <br />
                The company plans to commence operations towards the end of the
                current year.
              </p>
              <br />
              <h2 className="viss-miss-h2">Vision & Mission</h2>
              <br />
              <p className="respo-p">
                <strong>Vision:</strong> To be Asia’s
                preferred business jet partner for those who value time.
                <br />
                <br />
                <strong>Mission:</strong> To enable
                successful business leaders across Asia, make the best
                utilisation of their time as they crisscross the Globe by
                delivering safe, timely & reliable personalised travel services
                in the sky.
              </p>
            </div>
          </div>
        </div>
        {/* same as about us */}
        <div
          id="privatejet"
          className="about-us-container2 removescroll"

        >
          <div
            className="about-us-text addon"

          >
            <h2 className="whyy-h2"  >
              Why Private Jets?
            </h2>
            <br />
            <p className="respo-p">
              <strong>The need for business jets : </strong>A study released in
              2017 by Nexa Advisors, covering S&P 500 companies shows that over
              a five-year period, users of business aircraft grew their top line
              by a factor of 2.4 more than non-users on a weighted and indexed
              basis. <br /> Further, private jet users outperformed non-users by
              23 percent in revenue growth and users outperformed non-users by
              18 percent in market capitalization. <br /> At the end of 2023 the
              Business Jet fleet in the Asia Pacific region was at 1150 aircraft
              and is expected to grow significantly over the next few years.
            </p>
          </div>
          <div className="about-us-image">
            <img src="/Ph2.jpg" alt="About Us" />
          </div>
        </div>

        <Footerz />
        <p className="last">© 2024 Asia Jets</p>
      </div>
    </>
  );
};

export default Home;
