// import React, { useState, useEffect, useRef } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
// import { FaBars, FaTimes } from 'react-icons/fa';
// import '../Styles/Navbar.css';
// import logo from '../Assets/logooo.png';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [scroll, setScroll] = useState(false);
//   const [underSec, setUnderSet] = useState("home");
//   const menuRef = useRef(null);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScroll(true);
//     } else {
//       setScroll(false);
//     }
//   };

//   const handleClickOutside = (event) => {
//     if (menuRef.current && !menuRef.current.contains(event.target)) {
//       setIsOpen(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   return (
//     <nav  className={scroll ? 'scrolled' : ''}>
//       {scroll ? (
//       <img src="./logot.png" alt="logo" className="logo" />
//     ):(
//       <img src="./icon-w.png" alt="logo" className="logo" />
//     )}  
//     {/* logo change here */}
//       <div className="hamburger" onClick={toggleMenu}>
//         {isOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
//       </div>
//       <main className="fonts" ref={menuRef} className={isOpen ? 'open' : ''}>
//         <Link style={{marginRight:"1vh"}} onClick={()=>setUnderSet("home")} to="/#home">
//         {underSec == "home" ? <p style={{textDecoration:"underline"}}>Home</p>: <p className='border'>Home</p>} 
//         </Link>
//         <Link style={{marginRight:"1vh"}} onClick={()=>setUnderSet("about")} to="/#about">
//         {underSec == "about" ? <p style={{textDecoration:"underline"}}>About</p>: <p className='border'>About</p>} 
//         </Link>
//         <Link style={{marginRight:"1vh"}} onClick={()=>setUnderSet("private")}  to="/#privatejet">
//         {underSec == "private" ? <p style={{textDecoration:"underline"}}>Why Private Jets</p>:<p className='border'>Why Private Jets</p>}
//         </Link>
//         <div  style={{marginRight:"3vh"}} className="dropdown">
//           <Link onClick={()=>setUnderSet("prog")} to="/fractional">
//           {underSec == "prog" ? <p style={{textDecoration:"underline" ,left:"-10px"}}>Program</p>:<p className='border'>Program</p>}
//           </Link>
//           <div className="dropdown-content">
//             <Link onClick={()=>setUnderSet("prog")} className='under' to="/fractional">Fractional Membership</Link>
//             {/* <Link className='under' to="/">Membership</Link> */}
//             {/* <Link className='under' to="/">Charters</Link> */}
//           </div>
//         </div>
//         <div style={{marginRight:"2vh"}} className="dropdown">
//           <Link onClick={()=>setUnderSet("fleet")} to="/lear60xr">
//           {underSec == "fleet" ? <p style={{textDecoration:"underline"}}>Fleet</p> : <p className='border'>Fleet</p>}
//           </Link>
//           <div className="dropdown-content">
//             <Link className='under' onClick={()=>setUnderSet("fleet")} to="/lear60xr">Bombardier Learjet 60 XR</Link>
//             <Link className='under' onClick={()=>setUnderSet("fleet")} to="/challenger605"> Bombardier Challenger 605</Link> 
//           </div>
//         </div>
//         <Link onClick={()=>setUnderSet("Countries")} to="/country">
//         {underSec == "Countries" ? <p style={{textDecoration:"underline"}}>Countries</p> : <p className='border'>Countries</p>}
//         </Link>
//         <Link  onClick={()=>setUnderSet("contactus")}  to="/#contact">
//         {underSec == "contactus" ? <p style={{textDecoration:"underline"}}>Contact Us</p> : <p className='border'>Contact Us</p>}
//         </Link>
//       </main>
//     </nav>
//   );
// };

// export default Navbar;







import React, { useState, useEffect, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../Styles/Navbar.css';
import logo from '../Assets/logooo.png';

const Navbar = () =>
{
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [underSec, setUnderSet] = useState("home");
  const menuRef = useRef(null);

  const toggleMenu = () =>
  {
    setIsOpen(!isOpen);
  };

  const handleScroll = () =>
  {
    if (window.scrollY > 50)
    {
      setScroll(true);
    } else
    {
      setScroll(false);
    }
  };

  const handleClickOutside = (event) =>
  {
    if (menuRef.current && !menuRef.current.contains(event.target))
    {
      setIsOpen(false);
    }
  };

  useEffect(() =>
  {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () =>
    {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className={scroll ? 'scrolled' : ''}>
      {scroll ? (
        <img src="./logot.png" alt="logo" className="logo" />
      ) : (
        <img src="./icon-w.png" alt="logo" className="logo" />
      )}
      <div className={`hamburger ${ scroll ? 'scrolled' : '' }`} onClick={toggleMenu}>
        {isOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </div>
      <main className={`fonts ${ isOpen ? 'open' : '' }`} ref={menuRef}>
        <Link style={{ marginRight: "1vh" }} onClick={() =>
        {
          setUnderSet("home")
          toggleMenu()
        }} to="/#home">
          {underSec === "home" ? <p style={{ textDecoration: "underline" }}>Home</p> : <p className='border'>Home</p>}
        </Link>
        <Link style={{ marginRight: "1vh" }} onClick={() =>
        {
          setUnderSet("about")
          toggleMenu()
        }} to="/#about">
          {underSec === "about" ? <p style={{ textDecoration: "underline" }}>About Us</p> : <p className='border'>About Us</p>}
        </Link>
        <Link style={{ marginRight: "1vh" }} onClick={() =>
        {
          setUnderSet("private")
          toggleMenu()
        }} to="/#privatejet">
          {underSec === "private" ? <p style={{ textDecoration: "underline" }}>Why Private Jets</p> : <p className='border'>Why Private Jets</p>}
        </Link>
        <div style={{ marginRight: "3vh" }} className="dropdown">
          <Link onClick={() => setUnderSet("prog")
          } to="/fractional">
            {underSec === "prog" ? <p style={{ textDecoration: "underline", left: "-10px" }}>Program</p> : <p className='border'>Program</p>}
          </Link>
          <div className="dropdown-content">
            <Link onClick={() =>
            {
              setUnderSet("prog")
              toggleMenu()
            }} className='under' to="/fractional">Fractional Membership</Link>
            {/* <Link className='under' to="/">Membership</Link> */}
            {/* <Link className='under' to="/">Charters</Link> */}
          </div>
        </div>
        <div style={{ marginRight: "2vh" }} className="dropdown">
          <Link onClick={() => setUnderSet("fleet")

          } to="/lear60xr">
            {underSec === "fleet" ? <p style={{ textDecoration: "underline" }}>Fleet</p> : <p className='border'>Fleet</p>}
          </Link>
          <div className="dropdown-content">
            <Link className='under' onClick={() =>
            {
              setUnderSet("fleet")
              toggleMenu()
            }} to="/lear60xr">Bombardier Learjet 60 XR</Link>
            <Link className='under' onClick={() =>
            {
              setUnderSet("fleet")
              toggleMenu()
            }} to="/challenger605"> Bombardier Challenger 605</Link>
          </div>
        </div>
        <Link onClick={() =>
        {
          setUnderSet("Countries")
          toggleMenu()
        }} to="/country">
          {underSec === "Countries" ? <p style={{ textDecoration: "underline" }}>Countries</p> : <p className='border'>Countries</p>}
        </Link>
        <Link onClick={() =>
        {
          setUnderSet("contactus")
          toggleMenu()
        }} to="/#contact">
          {underSec === "contactus" ? <p style={{ textDecoration: "underline" }}>Contact Us</p> : <p className='border'>Contact Us</p>}
        </Link>
      </main>
    </nav>
  );
};

export default Navbar;
