import { useEffect } from "react";
import "../Styles/Fractional.css";
import Footerz from "./footer";
const Fractional = () =>
{
  useEffect(() =>
  {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="fractional" style={{ overflow: "hidden" }} className="wrapperf">
        <div className="herof">
          <div className="herof-vid">
            <video id="img1" autoPlay loop muted playsInline>
              <source src="/firstf.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="hero-text">
            {/* <p>Asia Jets Fractional Membership Program</p> */}
          </div>
        </div>
        <div className="container-fractional">
          <div className="textf">
            <div className="sec1">
              <p>
                Owning and Operating a business jet is a highly complex and
                expensive proposition. <br /> <br />
                There are 2 ways of owning <strong> 'Your'</strong> Jet.
              </p>
              <ul>
                <li className="sec-li">
                  By directly owning Your Aircraft and managing it (which is
                  expensive, manpower intensive and complex).
                </li>
                <li className="sec-li">
                  By joining a fractional ownership or membership program and
                  co-sharing an aircraft. Without the headaches & hassles associated
                  with ownership.
                </li>
              </ul>
            </div>
            <div className="sec1-img">
              <img src="/second2.jpg" alt="About Us" />
            </div>
          </div>
        </div>
        <div className="contentf">
          <div className="container-fractional">
            <h2> Fractional Membership Program</h2>
            <p>
              "Globally, Fractional ownership/membership has been a highly efficient
              corporate business tool for more than 30 years. <br />A Fractional
              program offers the flexibility, convenience, privacy, coupled with tax
              and time saving benefits of aircraft utilisation, without the
              attendant capital expense or management responsibilities. <br /> Just
              buy the share size appropriate to your annual flight requirement in a
              suitable aircraft make, model and size. <br /> Pay a one-time fee and
              a fixed amount per month for the hours you want to fly, without having
              to hire personnel, pay hangar rent, or attend to the many other
              details inherent in whole aircraft ownership. <br />
              Fractional providers worldwide, enjoy a remarkable safety record,
              given the huge number of operations they perform”.
            </p>
            <p>
              The <strong> Asia Jets Fractional Membership Program </strong> is tailor made for business owners & professionals in Asia that will
              allow you all the benefits of an aircraft at your disposal without the
              headaches and hassles of ‘owning’ one.
              <br /> Like the Timeshare industry, this program allows you to now
              ‘use’ the number of hours you have paid for. With the aircraft
              expenses shared amongst other members. <br /> So you get the benefit
              of using your time and money more effectively with utmost convenience,
              transparency minus the headaches.
            </p>
          </div>
        </div>
        {/* 2nd list */}
        <div className="textf2">
          <div className="sec1-img">
            <img src="/second.jpg" alt="About Us" />
          </div>
          <div className="container-fractional">
            <div className="sec1">
              <h2></h2>

              <ul>
                <li className="sec-li">
                  <strong>Customise your itinerary: </strong>Travel when you want
                  and with whom you want. In Your Aircraft.
                </li>
                <li className="sec-li">
                  <strong> Time efficient:</strong> Take advantage of 1000s of
                  private airports worldwide and 100s in the ASEAN Region.
                </li>
                <li className="sec-li">
                  <strong> Set your own schedule:</strong> Fly when you want to
                  without having to stick to inconvenient schedules and without
                  having to waste time with lengthy check-in processes at most
                  airports.
                </li>
                <li className="sec-li">
                  <strong> Travel in comfort in a highly sanitized space: </strong>
                  Flying in ‘Your Aircraft’ means goodbye to crowded airports &
                  aircraft.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footerz />
        <p className="last">© 2024 Asia Jets</p>
      </div>
    </>
  );
};

export default Fractional;
